<template>
  <div>
    <portal to="page-top-title">Company #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'companies-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/companies/${id}`" data-prop="company" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ company.name }}</column-info>
          <column-info title="Active">
            <badge-active :value="company.active"/>
          </column-info>
          <column-info title="Created At">{{ company.created_at }}</column-info>
          <column-info title="Updated At">{{ company.updated_at }}</column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Users" :url="`/users`" :url-query="{ company_id: id }" data-prop="users" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Owner</th>
          <th class="text-center">Active</th>
          <th class="text-center">2FA</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="user in users" :key="`users-tr-${user.id}`" :value="user">
          <td>{{ user.id }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td class="text-center">
            <badge-active :value="user.owner"/>
          </td>
          <td class="text-center">
            <badge-active :value="user.active"/>
          </td>
          <td class="text-center">
            <badge-active :value="user.tfa_enabled"/>
          </td>
          <td class="text-right">{{ user.created_at }}</td>
          <td class="text-right">{{ user.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'users-single', params: { id: user.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import BadgeActive from '@/views/components/simple/BadgeActive'
import company from '@/modules/strubs/company'
import user from '@/modules/strubs/user'

export default {
  name: 'CompaniesSingle',
  metaInfo () {
    return { title: 'Company #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    Btn,
    BadgeActive
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      company: company,
      users: [user]
    }
  }
}
</script>
